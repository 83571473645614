import { init, track } from "@amplitude/analytics-browser";

if (
  // @ts-ignore
  process.env.NODE_ENV === "production" &&
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
) {
  init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
}

export const logEvent = (event: string, eventData?: Record<string, any>) => {
  if (
    process.env.NODE_ENV !== "production" ||
    !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
  ) {
    return;
  }

  track(event, eventData);
};
