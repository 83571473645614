import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MdClear } from "@react-icons/all-files/md/MdClear";
import axios from "axios";
import { BlogPost, prisma, Publication, Tag } from "database";
import debounce from "lodash/debounce";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React, { useCallback } from "react";
import { BlogCard } from "ui/src/components/BlogCard";
import { Head } from "ui/src/components/Head";
import { Layout } from "ui/src/components/HomeDomain/Layout";
import { SignUp } from "ui/src/components/SignUp";
import { logEvent } from "utils/src/amplitude";

import type { GetStaticPaths, GetStaticProps } from "next";

// type Tag = string;
type Post = {
  title: string;
  description: string;
  href: string;
  readingTime: string;
  tags: Tag[];
  date: string;
  media: string;
};

// TODO: move this to a utils file
function getTitle(site: string) {
  switch (site) {
    case "rust":
      return "Rust";
    case "golang":
      return "Golang";
    case "typescript":
      return "TypeScript";
    case "python":
      return "Python";
    case "weekly":
      return "Developer Weekly";
  }
}

// TODO: move this to a utils file
function getDescription(site: string) {
  switch (site) {
    case "rust":
      return "A crabby little Rust blog.";
    case "golang":
      return "Curated Golang content.";
    case "typescript":
      return "A weekly TypeScript newsletter.";
    case "python":
      return "An endearing little Python newsletter.";
    case "weekly":
      return "A petite curation of developer news.";
  }
}

/**
 * Main index page
 *
 * @returns {React.ReactElement} main blog page
 */
export default function IndexPage({
  data,
  site,
}: {
  data: { posts: BlogPost[]; tags: Tag[] };
  site: string;
}) {
  const { isFallback } = useRouter();
  const inputRef = React.useRef<HTMLInputElement>();
  const [posts, setPosts] = React.useState<BlogPost[]>(data?.posts || []);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const iconColor = useColorModeValue("blackAlpha", "alpha");
  const linkColor = useColorModeValue("blue.600", "blue.200");

  const resetPosts = useCallback(() => {
    if (data) {
      setPosts(data.posts);
      setPage(1);
    }
  }, [data]);

  const searchIdx = debounce((value) => {
    logEvent("Search input event", {
      value: value,
    });

    setSearchValue(value);
  }, 500);
  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    searchIdx(target.value);

  React.useEffect(() => {
    /**
     *
     */
    async function run() {
      setIsSearching(true);

      if (!selectedTags.length && searchValue === "") {
        resetPosts();
        setIsSearching(false);
        return;
      }

      const { data } = await axios.get<BlogPost[]>(
        `/api/posts/search?tags=${selectedTags.join(
          ","
        )}&q=${searchValue}&site=${site}`
      );

      setPosts(data);
      setPage(1);
      setIsSearching(false);
    }

    void run();
  }, [selectedTags, searchValue, resetPosts, site]);

  if (isFallback) {
    return (
      <Layout>
        <Text>Loading...</Text>
      </Layout>
    );
  }

  const postLen = page * 12;
  const finalPosts = posts.slice(0, postLen);

  return (
    <Layout>
      <Box py={16}>
        <Head site={site} />
        <Container maxW="4xl">
          <VStack align="stretch" spacing={8}>
            <HStack spacing={8}>
              <Image
                alt="TheLastWeekIn.Dev logo"
                src="/assets/tlwi-dev-new-logo.svg"
                width="120"
                height="120"
              />
              <Heading fontSize={["2xl", "5xl"]}>{`The Last Week In ${getTitle(
                site
              )}`}</Heading>
            </HStack>
            <Text fontSize="xl">{getDescription(site)}</Text>
            <Text fontSize="xl">
              Curated by{" "}
              <Link
                as={NextLink}
                href="https://dennisokeeffe.com"
                fontWeight="bold"
                color={linkColor}
                rel="noreferrer"
                target="_blank"
                onClick={() => {
                  logEvent("Link to personal website clicked");
                }}
              >
                {`Dennis O'Keeffe`}
              </Link>{" "}
              and part of the{" "}
              <Link
                href="/"
                as={NextLink}
                fontWeight="bold"
                color={linkColor}
                onClick={() => {
                  logEvent("The Last Week In link clicked");
                }}
              >
                {`TheLastWeekIn.dev`}
              </Link>{" "}
              family.
            </Text>
            <SignUp />
            {data.tags.length && (
              <Wrap>
                {data.tags.map((tag) => {
                  const isSelected = selectedTags.includes(tag.id);
                  return (
                    <WrapItem key={tag.id}>
                      <Button
                        onClick={() => {
                          if (isSelected) {
                            logEvent("Deselect tag", {
                              tag: tag.name,
                            });

                            setSelectedTags(
                              selectedTags.filter(
                                (selectedTag) => selectedTag !== tag.id
                              )
                            );
                          } else {
                            logEvent("Select tag", {
                              tag: tag.name,
                            });

                            setSelectedTags([...selectedTags, tag.id]);
                          }
                        }}
                        colorScheme={isSelected ? "blue" : undefined}
                        size="xs"
                      >
                        {tag.name}
                      </Button>
                    </WrapItem>
                  );
                })}

                <WrapItem>
                  <Button
                    onClick={() => {
                      logEvent("Clear tag filters", {
                        selectedTagCount: selectedTags.length,
                      });
                      setSelectedTags([]);
                    }}
                    disabled={selectedTags.length === 0}
                    size="xs"
                    colorScheme="red"
                    variant="outline"
                  >
                    Clear tag filters
                  </Button>
                </WrapItem>
              </Wrap>
            )}
            <InputGroup>
              <Input
                // @ts-expect-error: Have no resolved this type issue yet
                ref={inputRef}
                onChange={handleSearch}
                placeholder="Search for a post"
              />
              <InputRightElement>
                {isSearching ? (
                  <Spinner size="xs" />
                ) : (
                  <IconButton
                    onClick={() => {
                      if (inputRef?.current) {
                        inputRef.current.value = "";
                      }
                      setSearchValue("");
                    }}
                    variant="ghost"
                    colorScheme={iconColor}
                    aria-label="Clear search input"
                    icon={<MdClear />}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {finalPosts.length ? (
              <SimpleGrid key="blog-grid" columns={1}>
                {finalPosts.map(
                  ({
                    title,
                    description,
                    createdAt,
                    edition,
                    readingTime,
                    // @ts-expect-error: Tags need to be added to the post type
                    tags = [],
                  }) => {
                    return (
                      <BlogCard
                        key={edition}
                        date={createdAt.toString()}
                        title={title}
                        // Hack fix for replacing the double quotes in the description
                        description={description.replace(/"/g, "")}
                        href={`/${site}/blog/${edition}`}
                        readingTime={readingTime}
                        tags={tags.map(
                          (record: { tag: Tag }) => record.tag.name
                        )}
                        LinkComponent={NextLink}
                      />
                    );
                  }
                )}
              </SimpleGrid>
            ) : (
              <Text>No posts found</Text>
            )}
            <HStack justifyContent="center">
              {postLen < posts.length && (
                <Button
                  minW="sm"
                  maxW="xl"
                  colorScheme="blue"
                  onClick={() => setPage(page + 1)}
                >
                  Load more
                </Button>
              )}
            </HStack>
            <HStack justifyContent="center">
              <Link href={`/${site}/blog`} as={NextLink} color={linkColor}>
                See all posts
              </Link>
            </HStack>
            {/* <NewsletterInputImpl /> */}
          </VStack>
        </Container>
      </Box>
    </Layout>
  );
}

type ContextParams = {
  data: {
    posts: Post[];
    tags: Tag[];
  };
  site: string;
};

function isContextParams(params: any): params is ContextParams {
  return typeof params.site === "string";
}

export const getStaticPaths: GetStaticPaths = async () => {
  const allPaths = ["rust", "weekly", "golang", "typescript", "python"];

  return {
    paths: allPaths.map((path) => ({
      params: {
        site: path,
      },
    })),
    fallback: true,
  };
};

// This function gets called at build time on server-side.
// It won't be called on client-side, so you can even do
// direct database queries. See the "Technical details" section.
export const getStaticProps: GetStaticProps = async (context) => {
  const { params } = context;
  if (!isContextParams(params)) {
    throw new Error("Invalid params");
  }
  const { site } = params;

  // const data = getDataForSubdomain(site);
  const getPublication = (publication: string) => {
    return {
      rust: Publication.RUST,
      weekly: Publication.WEEKLY,
      golang: Publication.GOLANG,
      typescript: Publication.TYPESCRIPT,
      python: Publication.PYTHON,
    }[publication];
  };

  const [posts, tags] = await Promise.all([
    prisma.blogPost.findMany({
      where: {
        publication: getPublication(site),
      },
      orderBy: {
        edition: "desc",
      },
      // you can remove this if you want to generate all sites at build time
      select: {
        title: true,
        description: true,
        createdAt: true,
        edition: true,
        readingTime: true,
        tags: { include: { tag: true } },
      },
    }),
    prisma.tag.findMany({
      where: {
        publication: getPublication(site),
      },
    }),
  ]);

  return {
    props: {
      site,
      data: {
        posts,
        tags,
      },
    },
    revalidate: false,
  };
};
